@import "../../theme/Variables.scss";
.header-top{
  .header-top-inner{
    p{
      text-align: center;
      margin: 0px;
    }
  }
}

.header {
  box-shadow: 0px 3px 6px #00000036;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #1f2326;
  .header-inner{
    padding: 15px 0px; 
  }
  .navbar {
    backdrop-filter: blur(28px);
    padding: 0px;
    .brand-logo {
      a {
        img {
          max-width: 200px;
          width: 100%;
        }
      }
    }

    .header-navbar-nav {
      //navbar-toggler
      .navbar-toggler{
        background-color: $primary;
        color: $dark;
        outline: none;
        font-size: 16px;
        outline: none;
        box-shadow: none;
      }

      .navbar-nav {
        width: 100%;
        .nav-item {
          margin: 0px 15px;
          .nav-link {
            color: $white;
            font-weight: $regular;
            position: relative;
            padding: 5px 0px;
            font-size: 14px;
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 20px;
              height: 1px;
              background-color: transparent;
            }
            &.active,
            &:hover {
              color: $tertiary;
              &::after {
                background-color: $tertiary;
              }
            }
          }
        }
      }
      @media screen and (max-width: 991px) and (min-width: 768px){
        .navbar-nav {
          .nav-item {
            margin: 0px 10px;
            .nav-link {
              font-size: 14px;
            }
          }
        }
      }
      @media only screen and (max-width: 575px){
        .navbar-nav{
          padding-top: 10px;
        }
      }
      @media only screen and (max-width: 767px){
        padding-top: 15px;
        .navbar-nav{
          .nav-item{
            margin: 4px 0px;
          }
        }
      }
    }

    //header-action
    .header-action {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      //user-drop
      .user-drop {
        .user-drop-btn {
          display: flex;
          align-items: center;
          border: 0px;
          outline: none;
          box-shadow: none;
          background-color: transparent;
          color: $primary;
          padding: 0px;
          .circle-img {
            margin-right: 8px;
            img {
              width: 24px;
              height: 24px;
              min-width: 24px;
              border-radius: 100%;
            }
          }
          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width:120px;
            @media screen and (max-width: 767px){
              max-width:85px;  
            }
          }
          i {
            margin-left: 6px;
            font-size: 16px;
          }
          &:after {
            display: none;
          }
        }

        .dropdown-menu {
          box-shadow: 0px 0px 10px #00000014;
          border-radius: 8px;
          padding: 10px;
          border: 1px solid $grey;
          margin-top: 14px;
          background-color: #1f2326;
          // &:after {
          //   content: "";
          //   position: absolute;
          //   width: 0;
          //   height: 0;
          //   border-left: 10px solid transparent;
          //   border-right: 10px solid transparent;
          //   border-bottom: 10px solid $white;
          //   top: -10px;
          //   right: 20px;
          // }
          li {
            background-color: #1f2326;
            .dropdown-item {
              color: $white;
              font-size: 14px;
              border-bottom: 1px solid rgba($white, 0.2);
              padding: 5px 0px;
              font-weight: $semiBold;
              i {
                margin-right: 8px;
              }
              &:hover {
                background-color: #1f2326;
                color: $primary;
              }
            }
            &:nth-last-child(1) {
              .dropdown-item {
                border-bottom: 0px;
              }
            }
          }
        }
        .dropdown-menu[data-bs-popper]{
          right: 0;
          left: auto;
        }
      }
    }
  }
}
