@import "./Variables.scss";

--Mange zoom label-- 

html {
    -webkit-text-size-adjust: none;
}

* {
  font-family: "Roboto", sans-serif;
}

body{
  background-color: $baseColor;
}

.theme-bg {
  background-color: $baseColor;
}

.main-container{
  min-height: calc(100vh - 427px);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: $baseColor;
}

::-webkit-scrollbar-thumb {
  background: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

h1 {
  font-size: 40px;
  line-height: 54px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 28px;
    line-height: 38px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 34px;
  }
}

h2 {
  font-size: 32px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 25px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 22px;
  }
}

h3 {
  font-size: 28px;
  line-height: 38px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
  }
}

h4 {
  font-size: 24px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

h5 {
  font-size: 22px;
  line-height: 30px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 22px;
  }
}

h6 {
  font-size: 20px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
}

p {
  font-weight: $regular;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  line-height: 20px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
}

a {
  font-weight: $regular;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
  }
}

//font style -------
.font14 {
  font-size: 14px;
}

// custom-checkbox
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
  color: rgba($white, 0.5);
  font-weight: $regular;
  // @media screen and (max-width: 1366px) and (min-width: 768px) {
  //   font-size: 14px;
  // }
  // @media only screen and (max-width: 767px) {
  //   font-size: 12px;
  // }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid rgba($white, 0.5);
    border-radius: 2px;
    top: 4px;
  }

  // input:checked ~ .checkmark {
  //   background-color: $dark;
  // }

  .checkmark {
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid rgba($white, 0.5);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    .checkmark{
      top: -1px;
    }
  }
}

.from-space-remove {
  .form-group {
    margin-bottom: 0px;
  }
}

// form input
.form-group {
  margin-bottom: 20px;
  label {
    color: $white;
    margin: 0px 0px 10px;
    display: block;
    font-weight: $regular;
    font-size: 16px;
    line-height: 20px;
    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .form-control {
    border-color: rgba($white, 0.2);
    border-radius: 6px;
    padding: 12px 12px;
    outline: none;
    box-shadow: none;
    font-size: 16px;
    line-height: 20px;
    background-color: rgba($white, 0.2);
    color: $white;
    @media screen and (max-width: 1366px) and (min-width: 768px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  textarea {
    resize: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  //right-icon-input
  .right-icon-input {
    position: relative;
    .form-control {
      padding-right: 40px;
    }
    span {
      position: absolute;
      top: 8px;
      right: 10px;
      z-index: 2;
      cursor: pointer;
      ion-icon {
        color: $white;
        font-size: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      img {
        width: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 18px;
        }
        @media only screen and (max-width: 767px) {
          width: 18px;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        fill: $white;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 18px;
          height: 18px;
        }
        @media only screen and (max-width: 767px) {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  //left-icon-input
  .left-icon-input {
    position: relative;
    .form-control {
      padding-left: 40px;
    }
    span {
      position: absolute;
      top: 8px;
      left: 15px;
      z-index: 2;
      i {
        color: $secondary;
        font-size: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      img {
        width: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 14px;
        }
        @media only screen and (max-width: 767px) {
          width: 12px;
        }
      }
    }
  }

  //double-icon-input
  .double-icon-input {
    position: relative;
    .form-control {
      padding-left: 40px;
      padding-right: 40px;
    }
    span {
      position: absolute;
      z-index: 2;
      i {
        color: $secondary;
        font-size: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          font-size: 18px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      img {
        width: 18px;
        @media screen and (max-width: 1366px) and (min-width: 768px) {
          width: 14px;
        }
        @media only screen and (max-width: 767px) {
          width: 12px;
        }
      }
      &.left-cion {
        top: 8px;
        left: 15px;
      }
      &.right-icon {
        top: 8px;
        right: 15px;
      }
    }
  }
  .form-select{
    option{
      color: rgba($dark, 1);
    }
  }
}

// .otp-position {
//   margin-left: 50px;
// }

//theme-button
.theme-button:disabled {
  opacity: 0.5;
  &.primary-btn {
    &:hover {
      background-color: $primary !important;
      border-color: $primary !important;
    }
  }
}
.theme-button {
  border: 1px solid transparent;
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 5px;
  @media screen and (max-width: 991px) and (min-width: 768px){
    padding: 8px 10px;
  }
  .spinner-border {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-left: 8px;
  }

  &.primary-btn {
    border-color: $primary;
    background-color: $primary;
    color: #1f2326;
    .spinner-border {
      color: #1f2326;
    }
    &:hover {
      border-color: $tertiary;
      background-color: $tertiary;
      // border-color: $dark;
      // background-color: $dark;
      color: $white;
      .spinner-border {
        color: $white;
      }
    }
  }
  &.grey-btn {
    border-color: #4c4f51;
    background-color: rgba($white, 0.2);
    color: $white;
    &:hover {
      border-color: $tertiary;
      background-color: $tertiary;
      color: $white;
    }
  }

  &.greybg-btn {
    border-color: $grey;
    background-color: $grey;
    color: $white;
    .spinner-border {
      color: $white;
    }
    &:hover {
      border-color: $dark;
      background-color: $dark;
      color: $white;
      .spinner-border {
        color: $white;
      }
    }
  }

  &.dark-btn {
    border-color: $dark;
    background-color: $dark;
    color: $white;
    .spinner-border {
      color: $white;
    }
    &:hover {
      border-color: $grey;
      background-color: $grey;
      color: $white;
      .spinner-border {
        color: $white;
      }
    }
  }
}

//link-btn
.link-btn {
  font-size: 22px;
  background-color: transparent;
  outline: none;
  border: 0px;
  padding: 0px;
  @media screen and (max-width: 1366px) and (min-width: 768px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
  }
  &.left-iocn-btn {
    i {
      margin-right: 8px;
    }
  }
  &.right-iocn-btn {
    i {
      margin-left: 8px;
    }
  }

  svg {
    margin-right: 8px;
  }
  &.white-btn {
    color: $white;
    svg {
      fill: $white;
    }
    i {
      color: $white;
    }
  }
  &.primary-btn {
    color: $primary;
    svg {
      fill: $primary;
    }
    i {
      color: $primary;
    }
  }
}

//colors
.success-color {
  color: $success !important;
}

.primary-color {
  color: $primary !important;
}

.danger-color {
  color: $danger !important;
}

.normal-color {
  color: $white !important;
}

//message info
.message {
  padding: 3px 0px;
  p {
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
    line-height: 18px;
  }
  &.error {
    p {
      color: $danger;
    }
  }
  &.success {
    p {
      color: $success;
    }
  }
  &.info-message {
    p {
      color: $white;
    }
  }
}

//common-page-head
.common-page-head {
  .inline-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    .theme-button {
      margin: 0px;
    }
  }
  @media only screen and (max-width: 767px){
    .inline-actions{
      justify-content: start;
    }
  }
}

//common-heading
.common-heading {
  .heading-title {
    margin-bottom: 20px;
    h2 {
      font-size: 30px;
      color: $white;
      font-weight: $semiBold;
      line-height: normal;
      position: relative;
      padding-bottom: 15px;
      display: flex;
      align-items: center;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 2px;
        background-color: $primary;
      }
      &.no-after {
        &:after {
          display: none;
        }
      }
      span {
        color: $tertiary;
        font-size: 12px;
        margin-left: 10px;
        margin-top: 8px;
      }
    }
    p {
      color: $white;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .brief {
    p {
      color: $white;
      font-size: 14px;
      line-height: 20px;
    }
  }
  &.text-center {
    .heading-title {
      h2 {
        &:after {
          left: 50%;
          width: 100px;
          margin-left: -50px;
        }
      }
    }
  }
}

//theme-swiper-slider
.theme-swiper-slider {
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: $dark;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        width: 24px;
        border-radius: 12px;
        min-width: 24px;
        background: $primary;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: $primary;
    width: 50px;
    height: 30px;
    border-radius: 0px;
    &:hover {
      background-color: $tertiary;
    }
  }

  .swiper-button-prev {
    left: auto;
    right: 70px;
    &:after {
      color: $white;
      font-size: 14px;
    }
  }
  .swiper-button-next {
    &:after {
      font-size: 14px;
      color: $white;
    }
  }

  @media only screen and (max-width: 767px){
    .swiper-button-prev,
    .swiper-button-next {
      background-color: rgba($primary, 0.4);
      width: 30px;
    }
    .swiper-button-prev {
      left: 5px;
      right: auto;
    }
    .swiper-button-next {
      right: 5px;
    }
  }
}

//common-breadcrumb
.common-breadcrumb {
  background-color: $secondary;
  padding: 20px 0px;
  .common-breadcrumb-inner {
    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      li {
        list-style: none;
        a {
          color: $white;
          font-size: 14px;
          line-height: normal;
          position: relative;
          padding: 0px 10px;
          display: inline-block;
          span {
            color: $primary;
          }
          &:after {
            content: ">";
            position: absolute;
            top: 0;
            right: -4px;
          }
        }
        &:nth-last-child(1) {
          a {
            &::after {
              display: none;
            }
          }
        }
        &:nth-child(1) {
          a {
            padding-left: 0px;
          }
        }
      }
    }
  }
}

//app-card
.app-card {
  background-color: rgba($white, 0.1);
  padding: 15px;
  border-radius: 10px;
  max-width: 180px;
  margin: 0px auto 30px;
  transition: transform 1s ease;
  .app-card-img {
    // background-color: rgba($white, 0.1);
    background-color: $primary;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    img {
      height: 60px;
      width: 60px;
      min-width: 60px;
      object-fit: contain;
      border-radius: 10px;
    }
    .custom-checkbox {
      position: absolute;
      top: 10px;
      left: 10px;
      .checkmark {
        &:after {
          border-color: $dark;
        }
      }

      input:checked ~ .checkmark {
        background-color: $white;
      }
    }
  }
  .app-card-img-recommand {
    background-color: $tertiary;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    img {
      height: 60px;
      width: 60px;
      min-width: 60px;
      object-fit: contain;
      border-radius: 10px;
    }
    .custom-checkbox {
      position: absolute;
      top: 10px;
      left: 10px;
      .checkmark {
        &:after {
          border-color: $dark;
        }
      }

      input:checked ~ .checkmark {
        background-color: $white;
      }
    }
  }
  .app-card-content {
    h3 {
      color: $white;
      font-size: 14px;
    }
    p {
      color: rgba($white, 0.5);
      font-size: 12px;
      min-height: 40px;
    }
  }
  &:hover {
    transform: scale(1.1);
    .app-card-img {
      background-color: $tertiary;
    }
  }
}

//theme-tab-section
.theme-tab-section {
  .theme-tab-nav {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        margin-bottom: 20px;
        span {
          color: rgba($white, 0.5);
          font-size: 16px;
          border-bottom: 1px solid transparent;
          padding-bottom: 5px;
          i {
            opacity: 0;
            margin-left: 10px;
          }
          &.active {
            color: $primary;
            border-bottom-color: $primary;
            i {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .theme-tab-content {
  }
  @media only screen and (max-width: 767px){
    .theme-tab-nav {
      ul {
        max-height: 150px;
        overflow-y: auto;
        margin-bottom: 30px;
        li {
          list-style: none;
          margin-bottom: 20px;
          span {
            color: rgba($white, 0.5);
            font-size: 16px;
            border-bottom: 1px solid transparent;
            padding-bottom: 5px;
            i {
              opacity: 0;
              margin-left: 10px;
            }
            &.active {
              color: $primary;
              border-bottom-color: $primary;
              i {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

//theme-collapse
.theme-collapse {
  .collapse-item {
    border-bottom: 1px solid rgba($white, 0.4);
    padding-bottom: 15px;
    margin-bottom: 15px;
    .collapse-head {
      a {
        font-size: 14px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          width: 40px;
          min-width: 40px;
          text-align: right;
          i {
          }
        }
        &.collapsed {
          span {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .collapse-content {
      padding: 15px 0px 0px;
      p {
        font-size: 14px;
        color: rgba($white, 0.6);
      }
    }
  }
}

//tech-card
.tech-card {
  background-color: rgba($white, 0.1);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  .tech-card-content {
    width: calc(100% - 150px);
    .name {
      h3 {
        color: $white;
        font-size: 15px;
      }
    }
    .pera {
      p {
        color: rgba($white, 0.5);
        font-size: 12px;
        word-break: break-word;
      }
    }
  }

  .content-status {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 150px;
    .date {
      text-align: right;
      span {
        color: $white;
        font-size: 12px;
        white-space: nowrap;
      }
    }
    .status {
      text-align: right;
      span {
        color: $white;
        font-size: 14px;
        &.completed {
          color: $primary;
        }
        &.processing {
          color: #f6ff76;
        }
        &.cancelled {
          color: #ff5353;
        }
      }
    }
  }
  @media only screen and (max-width: 767px){
    flex-flow: column;
    .tech-card-content {
      width: 100%;
      margin-bottom: 5px;
    }
    .content-status {
      width: 100%;
      .date {
        text-align: left;
      }
      .status {
        text-align: left;
      }
    }
  }
}

.payment-history-scroll {
  height: 360px;
  overflow-y: scroll;
}

.modal-custom-show {
  display: block;

  // &::after {
  //   position: absolute;
  //   content: "";
  //   top: 0;
  //   left: 0;
  //   background-color: rgba(0, 0, 0, 0.5);
  //   width: 100%;
  //   height: 100%;
  // }

  .modal-dialog {
    z-index: 1;
  }
}

//theme-modal
.theme-modal {
  background-color: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    .modal-content {
      border-radius: 33px;

      .modal-header {
        position: relative;
        padding-right: 55px;

        .modal-heading {
          display: flex;
          align-items: center;

          h3 {
            color: rgba($secondary, 1);
            font-size: 22px;
            line-height: normal;
            margin-bottom: 0px;
          }

          .theme-button {
            margin-left: 30px;
          }
        }

        .modal-closer {
          position: absolute;
          top: 20px;
          right: 20px;
          z-index: 999;
          .modal-closer-btn {
            border: 0px;
            margin: 0px;
            padding: 0px;
            font-size: 16px;
            box-shadow: none;
            outline: none;
            background-color: rgba($dark, 1);
            color: $white;
            width: 24px;
            height: 24px;
            min-width: 24px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
            }
          }
        }

        &.header-content-center {
          padding-left: 55px;

          .modal-heading {
            justify-content: center;
          }
        }
      }

      .modal-body {
        padding: 0px 50px 10px 50px;
        .modal-brief {
          p {
            font-size: 14px;
            font-weight: $semiBold;
            line-height: normal;
            color: $grey;
            
          }
        }
      }

      .action-btn{
        padding: 10px 50px 28px;
      }

      .modal-footer {
        border: 0px;
      }
    }
  }
}

//page not found style --------------
.page-not-main {
  text-align: center;
  margin: 50px 0;
  img {
    width: 420px;
    margin-bottom: 20px;
  }
  h1 {
    color: $white;
  }
}

.article-title {
  font-size: 16px;
  line-height: 16px;
  color: rgba($white, 1);
  margin-top: 20px;
}

.close {
  color: $danger !important;
}

.cursor-pointer {
  cursor: pointer;
}

.plus {
  margin-left: 3px;
  color: $primary !important ;
}

.link-color {
  color: wheat;
}

.NoRecord {
  color: white;
}

.image-loader {
  position: relative;
  left: 64px;
  top: 10px;
}

//common-home-process
.home-process {
  padding: 40px 0px 25px;
  .section-inner {
    .row {
      .col-md-4 {
        &:nth-last-child(1) {
          .process-info-item {
            border-right: 0px;
            &::after{
              display: none;
            }
          }
        }
      }
    }
    .process-info-item {
      text-align: center;
      margin-bottom: 15px;
      position: relative;
      min-height: 215px;
      &:after{
        content: "";
        position: absolute;
        top: 0;
        right: -15px;
        background-color: $primary;
        width: 1px;
        height: 100%;
      }
      img {
        width: 80px;
        margin-bottom: 20px;
        height: 80px;
      }
      .brief {
        min-height: 40px;
        margin-bottom: 15px;
        max-width: 220px;
        margin: 0px auto 15px;
        p {
          color: white;
          font-size: 14px;
          line-height: normal;
          line-height: 20px;
        }
      }
      .process-btn {
        .theme-button {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 767px) and (min-width: 576px){
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
//icon-input
.icon-input {
  position: relative;
  i {
    position: absolute;
    top: 3px;
    right: 18px;
    color: $primary;
  }
  &.unique-icon-input {
    i {
      margin-top: 40px;
    }
  }
}

//react-tel-input
.react-tel-input{
  .special-label{
    display: none;
  }
  .form-control{
    border:1px solid #454748 !important;
    background: rgba($white, 0.2) !important;
    width: 100% !important;
    height: 44px !important;
    border-radius: 6px;
  }
}

//text align css
.text-justify{
  text-align: justify !important;
}

//tnc-modal
.tnc-modal{
  .modal-content{
    .modal-body{
      padding-bottom: 0px !important;
      max-height: calc(100vh - 160px);
      overflow-y: auto;
       h1, h2, h3, h4, h5, h6{
        margin-bottom: 15px;
       }
      p {
        font-size: 14px;
        margin-bottom: 15px;
      }
    }
    .action-btn{}
  }
}
.theme-radio {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  border: 2px solid $grey;
  --border-width: 0px;
  --color-checked: transparent;
}

.theme-radio.radio-checked {
  border-color: $primary;
}

.theme-radio.radio-checked::part(mark) {
  background: $primary;
  border-width: 0px;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin: 2px auto;
}
.inline-radio {
  display: flex;
  align-items: center;

  .radio-label {
    display: flex;
    align-items: center;
    margin-right: 15px;

    .theme-radio {
      margin-right: 8px;
    }

    ion-label {
      color: $white;
      font-size: 14px;
      line-height: 18px;
      margin: 0px;
    }
  }
}

.pbs-img{
  max-height: 30px;
}

.pbs{
  margin-top: 2em;
}

//home-subscription
.home-subscription {
  padding: 40px 0px;
  .section-inner {
    .subscription-card {
      background-color: rgba($white, 0.1);
      padding: 30px;
      .card-top {
        margin-bottom: 30px;
        text-align: center;
        border-bottom: 1px solid $white;
        .name {
          margin-bottom: 15px;
          h3 {
            color: $white;
            font-size: 18px;
            line-height: normal;
            font-weight: $semiBold;
          }
        }
        .price {
          margin-bottom: 15px;
          p {
            color: $white;
            font-size: 30px;
            line-height: normal;
            font-weight: $regular;
            sub {
              font-size: 18px;
            }
          }
        }
      }
      .card-mid {
        .subscription-list {
          ul {
            margin: 0px;
            padding: 0px;
            li {
              list-style: none;
              margin-bottom: 20px;
              p {
                color: $white;
                font-size: 14px;
                line-height: normal;
                font-weight: $regular;
                display: flex;
                align-items: center;
                i {
                  margin-right: 15px;
                  font-size: 20px;
                }
              }
              &.not-in-subscription {
                p {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
      &.active {
        background-color: rgba($primary, 1);
        .card-top {
          .name {
            h3 {
              color: $dark;
            }
          }
          .price {
            margin-bottom: 30px;
            p {
              color: $dark;
              font-size: 40px;
            }
          }
        }
        .card-mid {
          .subscription-list {
            ul {
              li {
                margin-bottom: 25px;
                p {
                  color: $dark;
                }
              }
            }
          }
        }
      }
      @media screen and (max-width: 991px) and (min-width: 768px){
        min-height: 550px;
      }
      @media screen and (max-width: 767px) and (min-width: 576px){
        padding: 15px;
        .card-top {
          .name {
            h3 {
              font-size: 14px;
            }
          }
          .price {
            p {
              font-size: 22px;
              sub {
                font-size: 14px;
              }
            }
          }
        }
        .card-mid {
          .subscription-list {
            ul {
              li {
                p {
                  i {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
      @media only screen and (max-width: 575px){
          margin-bottom: 20px;
      }
    }
  }
}

//work-section
.work-section{
  padding: 40px 0px;
  .section-inner{
    text-align: center;
    iframe{
      max-width: 900px;
    }
  }
}