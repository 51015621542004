@import "../../theme/Variables.scss";

.footer {
  background-color: $dark;
  //footer-top
  .footer-top {
    padding: 50px 0px 20px;
    //footer-about
    .footer-about {
      margin-bottom: 30px;
      .footer-logo {
        margin-bottom: 20px;
        img {
          width: 200px;
        }
      }
      .brif {
        p {
          color: $white;
        }
      }
      @media screen and (max-width: 991px) and (min-width: 768px){
        .footer-logo {
          img {
            width: 150px;
          }
        }
      }
    }

    //footer-social
    .footer-social {
      margin-bottom: 25px;
      ul {
        margin: 0px;
        padding: 0px;
        display: flex;
        align-items: center;
        li {
          list-style: none;
          margin-right: 15px;
          a {
            display: inline-block;
            color: $white;
            font-size: 24px;
            &:hover {
              color: $primary;
            }
          }
        }
      }
    }

    //footer-link-list
    .footer-link-list {
      margin-bottom: 30px;
      @media only screen and (max-width: 600px) {
        margin-bottom: 40px;
      }
      .heading {
        margin-bottom: 20px;
        h3 {
          color: rgba($white, 1);
          font-size: 16px;
        }
      }

      .footer-links {
        ul {
          margin: 0px;
          padding: 0px;
          li {
            list-style: none;
            margin-bottom: 10px;
            a {
              color: $white;
              font-weight: 400;
              display: flex;
              align-items: center;
              opacity: 0.5;
              white-space: nowrap;
              i {
                margin-right: 12px;
              }
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  //footer-bottom
  .footer-bottom {
    background-color: $baseColor;
    text-align: center;
    padding-bottom: 20px;
    border-top: 1px solid rgba($secondary, 0.2);
    padding-top: 20px;
    p {
      color: rgba($white, 1);
    }
  }
}
