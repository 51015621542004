$baseColor: #1f2326;
$primary: #a1c6a3;
$secondary: #1f2326;
$tertiary: #ff9100;
$grey: #707070;
$dark: #000000;
$success: #00c53b;
$danger: #dc3545;
$warning: #ffc107;
$white: #fff;
$black: #000;
$blue: #475993;
//font waight
$light: 300;
$regular: 400;
$semiBold: 500;
$bold: 600;
