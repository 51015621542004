@keyframes ldio-fr7iv3pd6t-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(100px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(100px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-fr7iv3pd6t {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ldio-fr7iv3pd6t div {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  top: 60px;
  left: 20px;
}
.ldio-fr7iv3pd6t div:nth-child(1) {
  background: #10141d;
  animation: ldio-fr7iv3pd6t 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-fr7iv3pd6t div:nth-child(2) {
  background: #a1c6a3;
  animation: ldio-fr7iv3pd6t 1s linear infinite;
  animation-delay: 0s;
}
.ldio-fr7iv3pd6t div:nth-child(3) {
  background: #10141d;
  animation: ldio-fr7iv3pd6t-o 1s linear infinite;
  animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball-hchn3d2m5bs {
  width: 250px;
  height: 250px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-fr7iv3pd6t {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-fr7iv3pd6t div {
  box-sizing: content-box;
}

.loading-main {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.loading-txt {
  margin-top: -60px;
}
